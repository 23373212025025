<template>
  <div>
    <v-row style="margin: auto; justify-content: center" class="mt-1">
      <video width="870" height="480.59" controls class="mt-10" autoplay>
        <source
          :src="`${baseUrl}/merchant/stream-video?video_id=${this.$route.params.id}&client_id=${clientId}`"
          type="video/mp4"
        />
      </video>
    </v-row>
  </div>
</template>

<script>
import ApiService from "../../services/Api";
import { API_URL } from "@/utils/env.js";
import { showErrorMessage } from "@/utils/showError";
export default {
  data() {
    return {
      baseUrl: API_URL,
      clientId: null,
    };
  },
  onBeforeMount() {
    ApiService.GetRequest("/merchant/client")
      .then((res) => {
        this.clientId = res.data.result.data.client_id;
      })
      .catch((error) => {
        showErrorMessage(error);
      });
  },
};
</script>

<style lang="scss" scoped></style>
